import React from 'react'
import { PageProps } from 'gatsby'
import { useScroll } from '~/hooks'
import { useApi } from '~/siteApi'
import { useStaticQuery, graphql } from 'gatsby'
import { Head, Hero, Button, Form, Link, Footer } from '~/components'
import { scroller } from 'react-scroll'
import cn from 'classnames'
import * as st from '~/assets/styl/NotFound.module.styl'
import * as st1 from '~/assets/styl/AttendanceForm.module.styl'
import * as st2 from '~/assets/styl/ServiceChannels.module.styl'
import Banner from '~/assets/img/Atendimento.jpg'

const Success = ({ location }: PageProps) => {
  const {
    result: config,
  }: {
    result: {
      facebook?: string
      instagram?: string
      linkedin?: string
      youtube?: string
      whatsapp: string
      phone: string
    }
  } = useApi(
    {
      result: useStaticQuery(graphql`
        query {
          allConfigurations {
            nodes {
              facebook
              instagram
              linkedin
              whatsapp
              phone
              youtube
            }
          }
        }
      `).allConfigurations.nodes[0],
    },
    'configurations'
  )

  useScroll()

  const {
    constructions,
  }: { constructions: Array<{ name: string; id: string }> } = useApi(
    { constructions: [] },
    'constructions'
  )

  return (
    <>
      <Head
        location={location}
        title={'Vendas - ' + process.env.GATSBY_SITE_NAME}
      />
      <Hero
        title="Vendas"
        description={
          'Estamos preparados para atender você.\nRealize seu sonho com a Holder.'
        }
        image={Banner}
      >
        <div className={st.btns}>
          <Button
            className={st.btn}
            onClick={() =>
              scroller.scrollTo('Vendas', {
                smooth: true,
                duration: 500,
                offset:
                  -0.65 * document.getElementById('menu-bar').offsetHeight,
              })
            }
          >
            Fale com nosso consultores
          </Button>
        </div>
      </Hero>
      <section className={cn(st1.core, st1.attendance)} id="Vendas">
        <h2>Atendimento</h2>
        <p>
          Entre em contato e solicite um atendimento com nosso time de
          consultores.
        </p>
        <Form
          path="attendancies"
          idPrefix="contact"
          button={<Button>Enviar mensagem</Button>}
          loadingButton={<Button>Enviando...</Button>}
          inputs={[
            {
              name: 'name',
              label: 'Nome*',
            },
            {
              name: 'email',
              label: 'E-mail*',
              type: 'email',
            },
            {
              name: 'whatsapp',
              label: 'WhatsApp*',
              mask: 'phone',
            },
            {
              name: 'construction',
              label: 'Empreendimento*',
              type: 'select',
              options: constructions.map(({ name, id: value }) => ({
                name,
                value,
              })),
              placeholder: 'Selecione',
            },
            {
              name: 'message',
              label: 'Mensagem*',
              type: 'textarea',
              className: 'wide',
            },
            {
              name: 'privacy',
              label: (
                <>
                  Declaro que li e aceito a{' '}
                  <Link href="/politica-de-privacidade/">
                    Política de Privacidade
                  </Link>
                </>
              ),
              type: 'checkbox',
              className: 'wide',
            },
          ]}
        />
      </section>

      <section className={st2.core}>
        <div className={st2.container}>
          <h2>Canais de atendimento</h2>
          <div className={st2.links}>
            <Button
              href={`https://api.whatsapp.com/send?phone=55${config.whatsapp.replace(
                /\D/g,
                ''
              )}`}
              external
              type="big"
              className={cn(st2.btn, st2.whatsapp)}
            >
              Chamar no WhatsApp
            </Button>
            <Button
              href={`tel:${config.phone.replace(/\D/g, '')}`}
              external
              type="big"
              className={cn(st2.btn, st2.phone)}
            >
              {config.phone}
            </Button>
          </div>
          <p className={st2.location}>
            Av. Fernando Machado - D , 1261 - Centro, Chapecó - SC, 89802-111
          </p>
          <p className={st2.hours}>
            <strong>Horário de atendimento</strong>
            <br />
            Seg. a Sex das 9h às 18h
            <br />
            Sáb. das 9h às 12h
            <br />
            Domingos e feriados fechado
          </p>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default Success
